import React from 'react';
import './article-styles.css';
import barkley from '../../assets/barkley.jpg'
import germany from '../../assets/germany.png'
import volkswagen from '../../assets/volkswagen.svg'
import biden from '../../assets/biden.png'
import greta from '../../assets/greta.jpg'
import bogues from '../../assets/bogues.jpg'
import nash from '../../assets/nash.jpg'
import netflix from '../../assets/netflix.png'
import artest from '../../assets/artest.jpg'
import westbrook from '../../assets/westbrook.jpg'
import howard from '../../assets/howard.jpg'
import pangolin from '../../assets/pangolin.jpg'

// import article2_pic from './photo_ecommerce.png';


const ClimateAwards = () => {
  return (
    <article className="blog-article">
      <section class="climate-awards">
        <div class="content-wrapper">
            <h2>Climate Awards of 2020</h2>
            <p>As the year 2020 is finally over, it's time to announce the 2020 <b>climate awards</b>.</p>
            <p>Some characters achieved great things in 2020, and belong to the climate Green Team, but some did not, justifying their selection to the Grim Team. Let's discover our 2020 categories!</p>
            
            <ul>
                <li><a href="#head-coach">Head Coach</a></li>
                <li><a href="rebound-leader">Rebounds leader</a></li>
                <li><a href="#block-leader">Blocks leader</a></li>
                <li><a href="#assist-leader">Assists leader</a></li>
                <li><a href="#steal-leader">Steals leader</a></li>
                <li><a href="#point-leader">Points leader</a></li>
            </ul>

            <div className="award-section" id="head-coach">
            <h3>Head Coach</h3>
            <p>One day in November 2020 is enough to give the award this year. The election of <b>Joe Biden as the new head coach of the United States</b> translates to happy days for climate action.</p>
            <p>There are (at least) two major things Joe Biden is introducing:</p>

            <ul>
                <li>The United States will rejoin the Paris Agreement to limit climate change (they left under Trump administration).</li>
                <li>He will leverage low-carbon energy sources, i.e. renewables, hydropower, and nuclear energy.</li>
            </ul>

            <p>Joe Biden is a new figure in the climate change sector and is provided with powerful tools to make it happen. Enter: <b>Steve Nash of the Brooklyn Nets!</b></p>
            
            <div class="image-section">
                <div class='left'>
                <img src={biden} alt="Joe Biden" class="rounded-image"/>
                {/* <p className="image-attribution">Photo by [Photographer's Name] on [Source]</p> */}
                </div>
                <div class='right'>
                <img src={nash} alt="Steve Nash" class="rounded-image"/>
                <p className="image-attribution">Photo by Keith Allison from Baltimore, USA, CC BY-SA 2.0</p>
                </div>
            </div>
            </div>

            <div className="award-section" id="rebound-leader">
            <h3>Rebounds Leader</h3>
            <p>A <u>rebound</u> in climate change happens when some savings from energy efficiency are canceled out by behavior changes. For example, improving car fuel efficiency leads to people driving longer distances, buying bigger cars, or spending the money saved on a flight.</p>
            <p>In 2020, the rebound award goes to <b>Netflix</b>. With no transport and lockdowns everywhere, Netflix subscriptions boomed!</p>
            <p>The environmental impact of watching HD streaming is hotly debated, but it’s generally low. Watching one hour of Netflix equals driving 100m in a conventional car in terms of carbon emissions.</p>

            <div class="image-section">
                <div class='left'>
                <img src={netflix} alt="Netflix" class="rounded-image"/>
                </div>

                <div class='right'>

                <img src={barkley} alt="Charles Barkley" class="rounded-image"/>
                <p className="image-attribution">Copyright held by Steve Lipofsky Basketballphoto.com, CC BY 3.0</p>

                </div>
            </div>
            </div>

            <div className="award-section" id="block-leader">
            <h3>Blocks Leader</h3>
            <p>Germany wins the block award for carbon reductions after increasing coal usage when they closed nuclear plants. While renewable energy is great, keeping coal plants running increases carbon emissions.</p>

            <div class="image-section">
                <div class='left'>

                <img src={germany} alt="Germany Energy" class="rounded-image"/>
                </div>

                <div class='right'>

                <img src={howard} alt="Dwight Howard" class="rounded-image"/>
                <p className="image-attribution">Photo by Keith Allison from Owings Mills, USA, CC BY-SA 2.0</p>

                </div>

            </div>
            </div>

            {/* <!-- Add other sections like Assists leader, Steals leader, Points leader as needed --> */}
            <div className="award-section" id="assist-leader">
            <h3>Assists Leader</h3>
            <p>In basketball, an assist is attributed to a player who passes the ball to a teammate in a way that leads to a score. Who else than <b>Greta Thunberg</b> to fill this role?</p>
            <p>As the point guard of climate activism, Greta is young but impactful. Similarly, <b>Muggsy Bogues</b>, who was only 5 feet 3 inches, played against giants in the NBA. Let’s hope Greta’s activism ends better than Muggsy’s basketball career.</p>

            <div class="image-section">
            <div class='left'>
                <img src={greta} alt="Greta Thunberg" class="rounded-image"/>
                <p className="image-attribution">Photo by Vogler, CC BY-SA 4.0</p>

                </div>


                <div class='right'>
                <img src={bogues} alt="Muggsy Bogues" class="rounded-image"/>
                <p className="image-attribution">Photo by The Chronicle, Public domain, via Wikimedia Commons</p>

                </div>

            </div>
            </div>

            <div className="award-section" id="steal-leader">

            <h3>Steals Leader</h3>
            <p>In basketball, stealing the ball and scoring in a fast break is highly effective. Similarly, Volkswagen waited for Tesla to develop the EV market and then joined in, making them the stealers of Tesla’s fame.</p>

            <div class="image-section">
            <div class='left'>
                <img src={volkswagen} alt="Volkswagen" class="rounded-image"/>

                </div>
                <div class='right'>

                <img src={artest} alt="Ron Artest" class="rounded-image"/>
                <p className="image-attribution">Photo by Derral Chen, CC BY-SA 2.0</p>

                </div>
            </div>
            </div>

            <div className="award-section" id="point-leader">
            <h3>Points Leader</h3>
            <p>The leading scorer of 2020 is the <b>Pangolin</b>. Due to lockdowns from the COVID-19 pandemic, the Global Carbon Project reports a 7% reduction in emissions in 2020. To reach our target by 2050, we’d need a similar decrease every year!</p>

            <div class="image-section">
            <div class='left'>
                <img src={pangolin} alt="Pangolin" class="rounded-image"/>
                <p className="image-attribution">Photo by Sandip kumar, CC BY-SA 3.0</p>

                </div>
                <div class='right'>

                <img src={westbrook} alt="Russell Westbrook" class="rounded-image"/>
                <p className="image-attribution">Photo by Eastudios, CC BY-SA 2.0</p>

                </div>
            </div>
            </div>

            
        </div>
      </section>

    </article>

  );
};

export default ClimateAwards;