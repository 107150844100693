import React from 'react';
import './Home.css';
import myGreenDietImage from '../../assets/mygreendiet_crop3-removebg-preview.png'; // Adjust the path as necessary
import myTreevelImage from '../../assets/mytreevel_phone-removebg-preview.png'; // Adjust the path as necessary
import appleLogo from '../../assets/apple-store-badge.svg';

const Home = () => {
    return (
        <div className="home">
            <div className="image-container">
                <div className="mytreevel-container">
                    <h1>Mytreevel</h1>
                    <img src={myTreevelImage} alt="My Treevek" className="image" />
                    <div className="download-logos">
                        <a href="https://apps.apple.com/us/app/mytreevel/id1542280603" target="_blank" rel="noopener noreferrer">
                            <img src={appleLogo} alt="Download on Apple" className="download-logo" />
                        </a>
                        {/* <img src={googleLogo} alt="Download on Google" className="download-logo" /> */}
                    </div>

                </div>
                <div className="mygreendiet-container">
                    <h1>MyGreenDiet</h1>
                    <img src={myGreenDietImage} alt="MyGreenDiet" className="image" />
                    <div className="download-logos">
                        <a href="https://apps.apple.com/gb/app/mygreendiet/id1578494009" target="_blank" rel="noopener noreferrer">
                            <img src={appleLogo} alt="Download on Apple" className="download-logo" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
