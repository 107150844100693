import React, { useEffect } from 'react';
import './article-styles.css';
import emissionsmodes from '../../assets/emissions-mode.png';
import carbonemissionsmodes from '../../assets/carbon-emissions-transport.png'


const TravelComparison = () => {

  return (
    <article className="blog-article">
      <section class="intro-section">
        <div class="content-wrapper">
            <p>Which sector will win?</p>
            <p>Both automobile and aviation have received a lot of attention in the last couple of decades from environmentalists. The exhaust gases are sources of greenhouse gases (GHG), thus contributing to global warming. To what extent? And which transport mode should we prefer?</p>
            <h2>Transportation is a top 3 emission sector</h2>
            <p>First let's set the context for transport. In 2018, <a href="https://www.iea.org/reports/tracking-transport-2020" target="_blank" rel="noopener">IAE reports</a> for the transport sector 8.2Gt CO2. Adding other greenhouse gases, total direct emissions for transport are about 9Gt CO2, 18% of world CO2eq emissions. Adding indirect emissions (fuel extraction, vehicle manufacturing), transportation accounts for ~25% of world emissions.</p>
            <p>Road transport is 3/4 of the emissions, while aviation represents only 12%. However, aviation emissions are growing fast!</p>
            <img src={emissionsmodes} alt="CO2 Emissions by Mode" class="responsive-image"/>
        </div>
      </section>

      <section class="air-transport-section">
        <div class="content-wrapper">
            <h2>Air transport is the fastest growing source of GHG emissions</h2>
            <p>Flights account for only 12% of CO2 emissions from the transport sector, but represent 20% of CO2eq emissions including all greenhouse gases.</p>
            <p>There are several reasons to consider air transport a threat to sustainable development:</p>
            <ul>
                <li>The high altitude release of water vapour and nitrogen oxides creates more greenhouse gas effects than currently considered.</li>
                <li>Air transport is the fastest growing sector in transport, increasing twice as fast as car emissions.</li>
            </ul>
            <p>Compared to driving an hour to go camping, flying 1000kms makes a significant difference in carbon emissions!</p>
        </div>
      </section>

      <section class="suv-car-emissions">
        <div class="content-wrapper">
            <h2>Recent SUV demand drives an increase in car emissions</h2>
            <p>Road transport emissions have increased by 15% in the past decade, driven by a higher demand for SUVs. Despite improving car efficiency, road emissions continue to grow.</p>
            <p>"No problem: electric cars are booming and will drive carbon emissions to zero in the coming years."</p>
            <h2>Electric cars will not solve the carbon emissions problem</h2>
            <p>Electric cars help, but let's compare thermal and electric cars:</p>
            <ul>
                <li>Manufacturing: Higher carbon footprint for electric vehicles due to battery manufacturing.</li>
                <li>Infrastructure: Same roads are required for both car types.</li>
                <li>Energy: EV emissions depend on electricity production. In nuclear-powered France, emissions are low, while fossil-fuel-powered countries have higher emissions.</li>
            </ul>
            <p>It's not obvious how electric cars will drastically reduce car emissions. Here are more effective ways:</p>
            <ul>
                <li>Reduce speed on highways to save emissions and allow lighter vehicles.</li>
                <li>Design lighter and more aerodynamic cars for significant emission gains.</li>
            </ul>
        </div>
      </section>

      <section class="comparison-section">
        <div class="content-wrapper">
            <h2>Why both cars and planes should be dropped</h2>
            <p>On a 1km distance, here's how different transport modes compare:</p>
            <div className="image-wrapper">
            <img src={carbonemissionsmodes} alt="Carbon Emissions by Transport Modes" class="responsive-image"/>
            </div>
            <ul>
                <li>Cars (thermal, EV) and planes have the highest carbon emissions.</li>
                <li>EVs are more ecological than fuel cars due to energy efficiency.</li>
                <li>Carpooling reduces the carbon footprint significantly.</li>
                <li>Public transport, including buses and trains, is a low-carbon option.</li>
                <li>Electric bikes are eco-friendly for daily commutes.</li>
            </ul>
        </div>
      </section>

      <section class="conclusion-section">
        <div class="content-wrapper">
            <h3>Let's answer the original question of the post:</h3>
            <ul>
                <li>Planes should be avoided. Make every flight count!</li>
                <li>Choose a home with access to public transport, carpool when necessary, and avoid unnecessary car trips.</li>
            </ul>
            <p>With <a href="https://apps.apple.com/us/app/mytreevel/id1542280603" target="_blank" rel="noopener">Mytreevel</a>, we provide carbon information for your next trip and are building partnerships with transport companies to offer low-carbon options.</p>
        </div>
      </section>
    </article>
  );
};

export default TravelComparison;