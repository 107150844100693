import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Blog.css';
import basketball from '../../assets/basketball.png'
import planecar from '../../assets/plane-car.jpg'


const Blog = () => {
  useEffect(() => {
    document.title = "Carbon emission insights ";
    document.querySelector('meta[name="description"]').setAttribute("content", "Explore the latest articles on the carbon emissions of your personal lifestyle choices.");
  }, []);

  return (
    <div className="container">
      <main className="blog-content">
        <BlogList />
      </main>
    </div>
  );
};

const BlogList = () => (
  <>
    <h1 className="blog-title">Carbon emissions insights</h1>
    <p className="blog-intro">
        Explore the latest articles on the carbon emissions of your personal lifestyle choices.
    </p>
    <div className="blog-list">
        <article className="blog-post">
            <Link to="/blog/travel-comparison" className="blog-post-link">
                <article className="blog-post">
                <img src={planecar} alt="Plane and car" className="blog-post-image" />
                <div className="blog-post-content">
                    <h2>Transport emissions in 2020</h2>
                    <p className="blog-post-date">14 December 2020</p>
                    <p className="blog-post-extract">
                    Compare the emissions of the transportation modes in 2020.
                    </p>
                    <Link to="/blog/travel-comparison" className="read-more-link">
                    Read more →
                    </Link>
                </div>
                </article>
            </Link>
            <Link to="/blog/climate-awards" className="blog-post-link">
                <article className="blog-post">
                <img src={basketball} alt="basketball-court" className="blog-post-image" />
                <div className="blog-post-content">
                    <h2>Climate awards 2020</h2>
                    <p className="blog-post-date">January 22, 2021</p>
                    <p className="blog-post-extract">
                    Discover what are the compositions of the Dream Team and the Grim Team 2020.
                    </p>
                    <Link to="/blog/climate-awards" className="read-more-link">
                    Read more →
                    </Link>
                </div>
                </article>
            </Link>
        </article>
    </div>
  </>
);


export default Blog;