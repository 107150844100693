import { ReactComponent as Logo } from '../../assets/logo_150_150.svg';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import React from 'react';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="nav-links">
                <div className="logo-container">
                    <Link to="/"> {/* Wrap the logo in a Link component */}
                        <Logo className="logo" /> {/* Use the SVG component with a className */}
                    </Link>
                </div>
                <a href="/blog">Blog</a>
            </div>
        </nav>
    );
};

export default Navbar;
