import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Blog from './components/Blog/Blog';
import ClimateAwards from './components/Blog/ClimateAwards'; // Import the component for the route
import TravelComparison from './components/Blog/TravelComparison'; // Import the component for the route
import Footer from './components/Footer/Footer';
import './App.css';

const App = () => {
    return (
        <div className="app">
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blog/*" element={<Blog />} />
                <Route path="/blog/climate-awards" element={<ClimateAwards />} /> {/* Define the route */}
                <Route path="/blog/travel-comparison" element={<TravelComparison />} /> {/* Define the route */}
            </Routes>
            <Footer />
        </div>
    );
};

export default App;
